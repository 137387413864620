// Node Modules
import { connect } from 'react-redux';

// Actions
import {
  setDesktop,
  setMobile,
  setTablet,
} from '@/store/actions/viewPortActions';

// Components
import App from '../src/App';
import Button from '@/components/global-components/Button';


const NotFoundPage = ({
  viewPorts,
  setDesktop,
  setMobile,
  setTablet,
  content,
  sharedContent,
}) => {
  const metaData = {
    canonicalUrl: process.env.CPI_URL_HOSTNAME,
    headTitle: content.metaTitle || '',
    metaDescription: content.metaDescription || '',
  };

  return (
    <App
      viewPorts={viewPorts}
      setDesktop={setDesktop}
      setMobile={setMobile}
      setTablet={setTablet}
      isMobile={viewPorts.isMobile}
      isDesktop={viewPorts.isDesktop}
      meta={metaData}
      content={sharedContent}>
      <div className='error404'>
        <h1>404 - Page Not Found</h1>
        <Button
          className='secondary learn-more'
          text='Go back to Home'
          width='9.125rem'
          onClick={() => window.location='/'}
          styles={{
            margin: 'auto', fontSize: '0.875rem', textAlign: 'center', maxWidth: '10rem', width: '10rem', 
          }}
        />
      </div>
      <style jsx>{`
        .error404 {
          margin: auto;
          padding: 10%;
          text-align: center;
        }

      `}</style>
    </App>
  );
};

export const getStaticProps = async ({
  previewData, 
}) => {
  const content = [];

  return {
    props: {
      content,
    },
    revalidate: parseInt(
      process.env.CPI_REVALIDATE_STATIC_REGEN_TIME || '7200',
      10
    ),
  };
};

const mapStateToProps = ({
  viewPorts, 
}) => ({
  viewPorts,
});
const mapDispatchToProps = dispatch => ({
  setDesktop: () => dispatch(setDesktop()),
  setMobile: () => dispatch(setMobile()),
  setTablet: () => dispatch(setTablet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
